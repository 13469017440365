import React from "react"
import { insurance1, insurance2, insurance3 } from "../assets"
import { Layout, SEO } from "../components"
import "./insurance.css"
import "./main.css"

const OKinsurance = props => {
  return (
    <Layout>
      <SEO
        title="Скидка на страховку автомобиля от OKauto - оформить страховку не выходя из дома"
        description="Удобный сервис по оформлению страховки через наше приложение OKauto. Сервис позволяет не только узнавать о штрафах ПДД, но также оформить страховку используя только свой смартфон"
      />
      <div className="flex flex-row-ns flex-column insurance1__wrapper justify-center items-center">
        <div className="w-50-ns tl-ns tc">
          <h1 className="header ipad__font__header">
            Оформи страховку <br />
            <span className="di-ns dn">и получи скидку</span>
          </h1>
          <p className="f4-l f5 w-90-ns ipad__font__text">
            Оформить страховку с OKauto, не только удобно и быстро, но и
            выгодно.
          </p>
        </div>
        <img
          alt="скидка на страхование онлайн в OKauto"
          title="оформление страховки со скидкой в приложении OKauto"
          src={insurance1}
          className="insurance__photo__percent"
        />
      </div>

      <div className="flex flex-row-ns flex-column  insurance2__wrapper justify-center items-center">
        <img
          alt="фото расчитать стоимость страховки онлайн бесплатно в рк"
          title="расчет стоимости страхового полиса онлайн"
          src={insurance2}
          className="insurance__photo mr4-ns mb0-ns"
        />
        <div className="w-50-ns tl-ns tc">
          <h2 className="header ipad__font__header">
            Страховой полис,
            <br /> не выходя из дома
          </h2>
          <p className="f4-l f5 w-90-ns ipad__font__text">
            Теперь для оформления страховки не нужно ехать в страховую компанию.
            Вы можете самостоятельно сделать предварительный расчет стоимости
            страховки, оставить заявку в мобильном приложении OKauto, оплатить и
            получить электронный полис.
          </p>
        </div>
      </div>

      <div className="flex flex-row-ns flex-column  insurance3__wrapper justify-center items-center">
        <img
          alt="insurance3"
          title="insurance3"
          src={insurance3}
          className="insurance__photo dn-ns"
        />
        <div className="w-50-ns tl-ns tc">
          <h2 className="header ipad__font__header">
            Уведомление <br />о продлении полиса
          </h2>
          <p className="f4-l f5 w-90-ns ipad__font__text">
            Чтобы вы не забывали продлить срок действия страховки, можете
            заполнить форму в мобильном приложении OKauto. За 14 дней до
            истечения срока страхового полиса мы отправим вам уведомление о
            необходимости продления
          </p>
        </div>
        <img
          alt="узнать срок действия страхового полиса"
          title="напоминание о продлении страховки в смс"
          src={insurance3}
          className="insurance__photo mb0 dib-ns dn"
        />
      </div>
    </Layout>
  )
}

export default OKinsurance
